import {computed, ref, Ref} from '@vue/composition-api';
import {inject, makeDependency} from '@/container';
import {IOntologyNode} from '@/topic-ontology/types/IOntologyNode';
import {DependencyType} from '@/container/types/DependencyType';
import {UseTopicOntologyTree} from '@/topic-ontology/composables/UseTopicOntologyTree';
import {IOntologyTree} from '@/topic-ontology/types/IOntologyTree';

export const UNCATEGORIZED_ONTOLOGY_NODE: IOntologyNode = {
  id: -999,
  description: 'Uncategorized',
  children: [],
};

export const UseTopicOntologyTreeView = makeDependency((showUncategorized: Ref<boolean>) =>
  useTopicOntologyTreeview(showUncategorized, inject(UseTopicOntologyTree))
);

export const useTopicOntologyTreeview = (
  showUncategorized: Ref<boolean>,
  {ontologyTree}: DependencyType<typeof UseTopicOntologyTree>
) => {
  const search: Ref<string | null> = ref(null);
  const expanded: Ref<number[]> = ref([]);

  const items: Ref<IOntologyTree> = computed(() => {
    if (showUncategorized.value) {
      return ontologyTree.value.concat([UNCATEGORIZED_ONTOLOGY_NODE]);
    } else {
      return ontologyTree.value;
    }
  });

  return {
    items,
    search,
    expanded,
  };
};
