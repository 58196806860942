import {inject, makeGlobalSingleton} from '@/container';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import TaskTopic from '@/tasks/models/TaskTopic';
import {
  LoadingFlagsService,
  LoadingFlagsServiceClass,
} from '@/loading/services/LoadingFlagsService';

export const TopicOntologyService = makeGlobalSingleton(() =>
  makeTopicOntologyService(inject(LoadingFlagsService))
);

function makeTopicOntologyService($loadingFlags: LoadingFlagsServiceClass) {
  function fetchTopics() {
    return $loadingFlags.loadingHandler(
      LoadingFlag.LoadingAllTaskTopics,
      TaskTopic.api.fetch({
        includeChildren: true,
        includeRootNodeFlag: true,
      })
    );
  }
  return {fetchTopics};
}
