import {makeGlobalSingleton} from '@/container';
import TaskTopic from '@/tasks/models/TaskTopic';
import {IOntologyNode} from '@/topic-ontology/types/IOntologyNode';
import {Item} from '@vuex-orm/core/lib/data';
import {IOntologyTree} from '@/topic-ontology/types/IOntologyTree';

export const TopicOntologyTreeService = makeGlobalSingleton(() => makeTopicOntologyTreeService());

function makeTopicOntologyTreeService() {
  function compareOntologyNode(a: IOntologyNode, b: IOntologyNode) {
    return a.description.localeCompare(b.description);
  }

  function buildTopicTree(topics: TaskTopic[]): IOntologyTree {
    const rootTopics = topics.filter((topic) => topic.isRootNode);
    return rootTopics.map(buildTopicBranch).sort(compareOntologyNode);
  }

  function buildTopicBranch(topic: TaskTopic): IOntologyNode {
    const ontologyNode: IOntologyNode = {
      id: topic.id,
      description: topic.description,
      children: [],
    };

    topic?.children?.forEach((childId) => {
      const child: Item<TaskTopic> = TaskTopic.find(childId);
      if (child != null) {
        ontologyNode.children.push(buildTopicBranch(child));
      }
    });

    ontologyNode.children.sort(compareOntologyNode);

    return ontologyNode;
  }

  return {buildTopicTree};
}
