<template>
  <v-dialog v-model="tryItModal" width="50%">
    <v-card>
      <v-toolbar color="secondary">
        <v-toolbar-title class="white--text"> Task Preview </v-toolbar-title>
        <v-spacer />
        <v-btn large color="white" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <try-it-area
              v-if="task"
              :task="task"
              :task-state="computedTaskState"
              :grade="computedGrade"
              :feedback-by-part="computedFeedback"
              :response="computedResponse"
              :responses="computedResponses"
              :user="$auth.user"
              :task-index="0"
              :response-index="selectedResponseIndex"
              :enable-select="enableSelect"
              try-it
              v-on="$listeners"
              @change-selected-response="changeSelectedResponse"
              @add-try-it-response="addTryItResponse"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Task from '@/tasks/models/Task';
import TryItArea from '@/tasks/components/TryItArea';
import Grade from '@/grades/models/Grade';
import TaskResponse from '@/tasks/models/TaskResponse';
import TaskState from '@/task-states/models/TaskState';
import {LoadingFlag} from '@/loading/types/LoadingFlags';

export default {
  name: 'TryItDialog',
  components: {
    TryItArea,
  },

  props: {
    task: {
      type: Task,
      default: null,
    },
    selectedTaskId: {
      type: [Number, String],
    },
    enableSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    selectedResponseIndex: 0,
    tryItData: {
      responses: [],
      grades: [],
      taskState: null,
    },
  }),
  computed: {
    tryItModal: {
      get() {
        return this.task !== null;
      },
      set(value) {
        if (!value) {
          this.close();
        }
      },
    },
    computedResponses() {
      return this.tryItData.responses;
    },
    computedResponse() {
      const response = this.tryItData.responses[this.selectedResponseIndex];
      return response || null;
    },
    computedTaskState() {
      const response = this.computedResponse;
      return response && response.taskState
        ? response.taskState
        : TaskState.fullQuery().where('taskId', this.task.id).first();
    },
    computedFeedback() {
      const response = this.computedResponse;
      return response ? new TaskResponse(response).getFeedbackByPart() : null;
    },
    computedGrade() {
      const grade = this.tryItData.grades[this.selectedResponseIndex];
      return grade || null;
    },
  },
  watch: {
    task() {
      if (this.task) {
        this.updateTaskState();
      }
    },
  },
  methods: {
    close() {
      this.clearTryItData();
      this.$emit('close');
    },
    clearTryItData() {
      this.tryItData.taskState = null;
      this.tryItData.responses = [];
      this.tryItData.grades = [];
    },
    changeSelectedResponse(newIndex) {
      this.selectedResponseIndex = newIndex;
    },
    addTryItResponse({grade, response}) {
      this.tryItData.grades.push(new Grade(grade));
      this.tryItData.responses.push(new TaskResponse(response));
    },
    updateTaskState() {
      this.clearTryItData();
      if (this.task.isRandomizable() && this.computedTaskState === null) {
        this.$loadingFlags.loadingHandler(
          LoadingFlag.AssignmentTaskState,
          TaskState.api.issue(this.task.id, {tryIt: true})
        );
      }
    },
  },
};
</script>
}
