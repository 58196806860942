<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{on, attrs}">
        <v-btn
          v-if="isPreviewable"
          fab
          x-small
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click.stop="btnClickedHandler(buttonTypes.PREVIEW)"
        >
          <v-icon dark>
            {{ $i('assignment.previewAssignment') }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('previewTips.' + appType) }}</span>
    </v-tooltip>
    <v-tooltip v-if="canGrantExtensions && appType === 'assignmentPreview'" top>
      <template v-slot:activator="{on, attrs}">
        <v-btn
          v-if="isPreviewable"
          fab
          x-small
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click.stop="btnClickedHandler(buttonTypes.EXTENSIONS)"
        >
          <v-icon dark>
            {{ $i('assignment.extensions') }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('previewTips.extensions') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'BtnCellRenderer',
  data() {
    return {
      buttonTypes: {
        PREVIEW: 'preview',
        EXTENSIONS: 'extensions',
      },
    };
  },
  computed: {
    appType() {
      if (
        this.params.data.hasOwnProperty('manuallyGraded') ||
        this.params.data.hasOwnProperty('task')
      ) {
        return 'taskPreview';
      }
      return 'assignmentPreview';
    },
    isPreviewable() {
      if (this.params.data.hasOwnProperty('manuallyGraded')) {
        return this.params.data.isPreviewable();
      } else if (this.params.data.hasOwnProperty('task')) {
        return this.params.data.task.isPreviewable();
      }

      return true;
    },
    canGrantExtensions() {
      if (this.params.data.assignment) {
        return this.$gate.can('grantExtensionsFor', 'Assignment', this.params.data.assignment);
      }
      return false;
    },
  },
  methods: {
    btnClickedHandler(buttonType) {
      if (buttonType === this.buttonTypes.PREVIEW) {
        this.params.viewPreview(this.params);
        return;
      }

      if (buttonType === this.buttonTypes.EXTENSIONS) {
        this.params.viewExtensions(this.params);
      }
    },
  },
});
</script>
