<template>
  <stemble-latex :content="content" />
</template>

<script>
import Vue from 'vue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default Vue.extend({
  name: 'LatexRenderer',
  components: {StembleLatex},
  computed: {
    content() {
      return this.params.value;
    },
  },
});
</script>
