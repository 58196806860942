<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{on, attrs}">
        <v-btn
          v-if="isPreviewable"
          fab
          x-small
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click.stop="previewTask()"
        >
          <v-icon dark>
            {{ $i('assignment.previewAssignment') }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('previewTips.taskPreview') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskTableCellRenderer',
  computed: {
    isPreviewable() {
      if (this.params.data.hasOwnProperty('manuallyGraded')) {
        return this.params.data.isPreviewable();
      }
      return true;
    },
    canUpdateAssignment() {
      return this.params.canUpdateAssignment;
    },
  },
  methods: {
    previewTask() {
      this.params.viewPreview(this.params);
    },
    deleteTask() {
      this.params.deleteTask(this.params);
    },
  },
});
</script>
