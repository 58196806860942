









































import {defineComponent, onMounted, PropType, toRef} from '@vue/composition-api';
import {inject} from '@/container';
import {TopicOntologyService} from '@/topic-ontology/services/TopicOntologyService';
import {UseTopicOntologyTreeView} from '@/topic-ontology/composables/UseTopicOntologyTreeView';
import {useVModel} from '@vueuse/core';
import StembleLoader from '@/loading/components/StembleLoader.vue';

export default defineComponent({
  components: {StembleLoader},
  props: {
    showUncategorized: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    selectionType: {
      type: String as PropType<'not-leaf-or-independent' | 'independent' | 'leaf'>,
      default: 'not-leaf-or-independent',
    },
  },
  setup(props, {attrs}) {
    const topicOntologyService = inject(TopicOntologyService);

    const modelValue = useVModel(attrs);
    const {items, search, expanded} = inject(
      UseTopicOntologyTreeView,
      toRef(props, 'showUncategorized')
    );

    onMounted(() => {
      topicOntologyService.fetchTopics();
    });

    return {
      modelValue,
      items,
      search,
      expanded,
    };
  },
});
