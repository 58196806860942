<template>
  <v-tooltip open-delay="500" :open-on-focus="false" top :disabled="disableTooltip">
    <template v-slot:activator="{on, attrs}">
      <div v-bind="attrs" v-on="on" @click="disableTooltip = true">
        {{ content }}
        <v-icon v-if="showIcon" class="mb-2 v-icon" small color="#3378AF">
          mdi-pencil-circle
        </v-icon>
      </div>
    </template>
    <span>
      {{ $t(translationKey) }}
    </span>
  </v-tooltip>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  name: 'EditIconInCellRenderer',
  data() {
    return {
      disableTooltip: false,
    };
  },
  computed: {
    content() {
      if (this.params.formatDate && this.params.value) {
        return moment(this.params.value).format('ddd, MMM DD [at] h:mm a');
      }

      return this.params.value;
    },
    translationKey() {
      return this.params.translationKey ?? '';
    },
    showIcon() {
      return this.params.colDef.editable;
    },
  },
});
</script>
