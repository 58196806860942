import _ from 'lodash';

export type KeyFn<T> = (item: T) => any;

export function getAllChanged<T>(
  newArray: T[] | Set<T>,
  oldArray: T[] | Set<T>,
  comparator?: (item1: T, item2: T) => boolean
): Changed<T> {
  comparator = comparator ?? _.isEqual;
  newArray = [...newArray];
  oldArray = [...oldArray];

  return {
    added: _.differenceWith(newArray, oldArray, comparator),
    removed: _.differenceWith(oldArray, newArray, comparator),
    kept: _.intersectionWith(newArray, oldArray, comparator),
  };
}

export type Changed<T> = {
  added: T[];
  removed: T[];
  kept: T[];
};
