<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="text-capitalize">
        {{ $t('question-bank') }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-row
      class="pt-4 pb-8"
      style="padding-left: 48px; padding-right: 48px"
      justify="start"
      align="end"
    >
      <v-col class="pb-0" cols="3">
        <v-autocomplete
          v-model="taskTypes.selected"
          :items="allTaskTypes"
          item-text="description"
          item-value="id"
          hide-details
          deletable-chips
          small-chips
          :label="$t('addTasks.filterByTaskType')"
          multiple
          clearable
          :search-input.sync="taskTypes.searchInput"
          @change="
            taskTypes.searchInput = null;
            updateGridFilter();
          "
        >
          <template v-slot:selection="{item, index}">
            <v-chip v-if="index <= maxTaskTypeChipsShown - 1" small>
              <span>{{ item.description }}</span>
            </v-chip>
            <span v-if="index === maxTaskTypeChipsShown" class="grey--text caption"
              >(+{{ taskTypes.selected.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="pb-0" cols="3">
        <v-text-field
          id="quickFilterInput"
          v-model="quickFilterText"
          hide-details
          clearable
          :label="$t('addTasks.filterByTitle')"
        />
      </v-col>
      <v-spacer />
      <v-col class="pb-0" cols="3">
        <v-btn
          elevation="0"
          color="secondary"
          rounded
          :disabled="!addEnabled"
          @click="addTasksToAssignment"
        >
          <v-icon medium> mdi-plus </v-icon>
          {{ $t('addTasks.addTaskButton') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="width: 100%">
      <template>
        <v-row no-gutters>
          <v-col class="width: 100%; height: 600px">
            <topic-ontology v-model="selectedTopicIds" :show-uncategorized="showUncategorized" />
          </v-col>
          <v-col class="col-9">
            <ag-grid-vue
              id="questionsBankGrid"
              style="width: 100%; height: 600px"
              class="ag-theme-material bordered-table"
              :row-selection="rowSelection"
              :row-multi-select-with-click="true"
              :grid-options="gridOptions"
              :column-defs="columnDefs"
              :row-data="allTasks"
              :default-col-def="defaultColDef"
              :animate-rows="true"
              :sorting-order="sortingOrder"
              :overlay-loading-template="overlayLoadingTemplate"
              :overlay-no-rows-template="overlayNoRowsTemplate"
              :components="components"
              :post-process-popup="postProcessPopup"
              :allow-context-menu-with-control-key="true"
              :icons="icons"
              :quick-filter-text="quickFilterText"
              :is-external-filter-present="isExternalFilterPresent"
              :does-external-filter-pass="doesExternalFilterPass"
              @row-selected="rowSelected"
              @grid-ready="onGridReady"
            />
          </v-col>
        </v-row>
      </template>
    </v-row>
    <try-it-dialog
      :task="previewTask"
      :enable-select="true"
      @close="previewTask = null"
      @task-select="selectTask"
    />
  </v-card>
</template>

<script>
import TryItDialog from '@/tasks/components/TryItDialog';
import {AgGridVue} from 'ag-grid-vue';
import Task from '@/tasks/models/Task';
import LatexRenderer from '@/assignments/components/LatexRenderer';
import BtnCellRenderer from '@/assignments/components/BtnCellRenderer';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import TaskType from '@/tasks/models/TaskType';
import TopicOntology from '@/topic-ontology/components/TopicOntology';
import {UNCATEGORIZED_ONTOLOGY_NODE} from '@/topic-ontology/composables/UseTopicOntologyTreeView';
import {buildDefaultGridOptions} from '@/ag-grid/utils/buildDefaultGridOptions';
import {Paginator} from '@/common/utils/pagination';
import {getTasks} from '@/tasks/api/tasks';

export default {
  name: 'TaskGallery',
  components: {
    TryItDialog,
    AgGridVue,
    TopicOntology,
  },
  props: {
    selectedTaskIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    maxTaskTypeChipsShown: 1,
    gridOptions: null,
    gridApi: null,
    addEnabled: false,
    columnApi: null,
    defaultColDef: null,
    rowSelection: null,
    columnDefs: null,
    components: null,
    postProcessPopup: null,
    getRowNodeId: null,
    sheet: false,
    icons: null,
    editType: null,
    frameworkComponents: null,
    quickFilterText: null,
    previewTask: null,
    selectedTopicIds: [],
    taskTypes: {
      selected: [],
      searchInput: null,
    },
    overlayLoadingTemplate: null,
    overlayNoRowsTemplate: null,
  }),
  computed: {
    showUncategorized() {
      return this.allTasks.filter((task) => task.ancestorTopicIdsSet.size === 0).length > 0;
    },
    allTasks() {
      return Task.fullQuery()
        .all()
        .filter((task) => task.status === 'published');
    },
    allTaskTypes() {
      return TaskType.all();
    },
    selectedTaskIdsSet() {
      return new Set(this.selectedTaskIds);
    },
  },
  watch: {
    selectedTaskIds() {
      this.updateGridFilter();
    },
    selectedTopicIds() {
      this.updateGridFilter();
    },
  },
  created() {
    this.fetchAvailableTasks();
  },
  beforeMount() {
    this.getRowNodeId = (data) => {
      return data.id;
    };
    this.gridOptions = {
      ...buildDefaultGridOptions(),
      headerHeight: 48,
      suppressCellSelection: true,
    };
    this.columnDefs = [
      {
        headerName: '',
        checkboxSelection: true,
        suppressMenu: true,
        filter: false,
        suppressMovable: true,
        maxWidth: 30,
      },
      {
        headerName: this.$t('taskGalleryTable.taskId'),
        field: 'id',
        suppressMenu: true,
        filter: false,
        suppressMovable: true,
        hide: true,
        maxWidth: 120,
      },
      {
        headerName: this.$t('taskGalleryTable.title'),
        field: 'title',
        minWidth: 500,
        wrapText: true,
        suppressMenu: true,
        filter: false,
        suppressMovable: true,
        cellRendererFramework: LatexRenderer,
      },
      {
        headerName: this.$t('taskGalleryTable.question-type'),
        field: 'taskTypeIds',
        minWidth: 120,
        valueGetter: ({data}) =>
          TaskType.findIn(Array.from(data.taskTypeIds))
            .map((taskType) => taskType.description)
            .join(', '),
      },
      // {
      //   headerName: this.$t('grading-method'),
      //   field: 'grading_method',
      //   maxWidth: 200,
      // },
      {
        headerName: 'AI Graded',
        field: 'isAiGraded',
        filter: false,
        minWidth: 30,
        suppressMovable: true,
        valueFormatter: ({value}) => {
          if (value) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      {
        headerName: 'Preview',
        headerClass: 'hide-header-preview',
        field: 'preview',
        cellClass: 'hide-preview',
        suppressMenu: true,
        filter: false,
        sortable: false,
        suppressMovable: true,
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          viewPreview: ({data}) => {
            this.openPreviewTask(data);
          },
        },
      },
    ];

    this.sortingOrder = ['desc', 'asc', null];

    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      filter: true,
      suppressAutoSize: true,
      editable: false,
      resizable: true,
      headerCheckboxSelection: false,
    };

    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your data are loading</span>';
    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">' +
      this.$t('noDataAvailable') +
      '</span>';
    this.rowSelection = 'multiple';
    this.onSelectionChanged = 'onSelectionChanged';
  },
  methods: {
    openPreviewTask(data) {
      this.previewTask = data;
    },
    fetchAvailableTasks() {
      const paginator = new Paginator(
        async (pagination) => {
          const tasks = await getTasks({
            includeAncestorTopics: true,
            includeTaskTypes: true,
            ...pagination,
          });

          await Task.insert({data: tasks.data.data});

          return tasks;
        },
        {pageSize: 300}
      );

      this.$loadingFlags.loadingHandler(LoadingFlag.LoadingAllTasks, paginator.stream());
      this.$loadingFlags.loadingHandler(LoadingFlag.LoadingAllTaskTypes, TaskType.api.fetch());
    },
    addTasksToAssignment() {
      const tasks = this.gridApi.getSelectedRows();
      this.$emit('add-tasks', {tasks});
      this.gridApi.deselectAll();
      this.close();
    },
    updateGridFilter() {
      this.gridApi.onFilterChanged();
    },
    taskFilter(task) {
      if (this.selectedTaskIdsSet.has(task.id) || task.manuallyGraded) {
        return false;
      }

      return this.taskMatchesSelectedTopicAncestors(task) && this.taskMatchesSelectedTypes(task);
    },
    taskMatchesSelectedTopicAncestors(task) {
      return (
        this.selectedTopicIds.length === 0 ||
        this.selectedTopicIds.filter(
          (value) =>
            task.ancestorTopicIdsSet.has(value) ||
            (value === UNCATEGORIZED_ONTOLOGY_NODE.id && task.ancestorTopicIdsSet.size === 0)
        ).length > 0
      );
    },
    taskMatchesSelectedTypes(task) {
      return (
        this.taskTypes.selected.length === 0 ||
        this.taskTypes.selected.filter((value) => task.taskTypeIdsSet.has(value)).length > 0
      );
    },
    selectTask(taskId) {
      this.gridApi.forEachNode((node) => {
        if (node.data.id === taskId) {
          node.setSelected(true);
        }
      });
    },
    isExternalFilterPresent() {
      return true;
    },
    doesExternalFilterPass(node) {
      return this.taskFilter(node.data);
    },
    onGridReady({api}) {
      this.gridApi = api;
    },
    rowSelected() {
      this.addEnabled = this.gridApi.getSelectedRows().length > 0;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.bordered-table {
  border: 1px solid #e2e2e2;
}

.hide-header-preview {
  width: 0 !important;
  min-width: 0 !important;
}

.ag-row:not(.ag-row-hover) .hide-preview {
  width: 0 !important;
  padding: 0 !important;
}

.ag-row:not(.ag-row-hover) .ag-cell .hide-preview {
  width: 0 !important;
  padding: 0 !important;
}
</style>
