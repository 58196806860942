var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('v-container',{staticClass:"px-8",attrs:{"id":"Assignment","fluid":"","tag":"section"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogs.addQuestionsToAssignment),callback:function ($$v) {_vm.$set(_vm.dialogs, "addQuestionsToAssignment", $$v)},expression:"dialogs.addQuestionsToAssignment"}},[_c('task-gallery',{attrs:{"selected-task-ids":_vm.selectedTaskIds},on:{"add-tasks":_vm.onAddTasks,"close":function($event){_vm.dialogs.addQuestionsToAssignment = false}}})],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('v-btn',{attrs:{"color":"secondary","href":("/courses/" + (_vm.course.id) + "/assignments/" + (_vm.assignment.id))}},[_vm._v(" Back ")])],1)],1),(_vm.canUpdateAssignment || _vm.canAssignAssignment)?_c('v-card',{staticClass:"pa-3",attrs:{"data-test":"assignment-tasks-card"}},[_c('v-row',{attrs:{"align":"end","no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"d-inline-block buttons-full-width pl-7"},[_c('div',{staticClass:"task-buttons"},[_c('p',{staticClass:"d-inline font-weight-bold mr-4",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('questions').toUpperCase())+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._b({attrs:{"elevation":"4","small":"","color":"secondary","disabled":!_vm.canUpdateAssignment,"data-test":"add-task-button","data-test-tooltip-activator":"add-task-button"},on:{"click":function($event){$event.stopPropagation();_vm.dialogs.addQuestionsToAssignment = true}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"left":"","medium":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('Add Tasks'))+" ")],1)],1)]}}],null,false,2007973365)},[_c('span',{attrs:{"data-test-tooltip-content-for":"add-task-button"}},[_vm._v(_vm._s(_vm.$t( _vm.canUpdateAssignment ? 'assignmentPage.tooltips.addTask' : 'assignmentPage.noEditPermission' )))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._b({attrs:{"disabled":_vm.selectedRows.length === 0 || !_vm.canUpdateAssignment,"small":"","outlined":"","elevation":"4","color":"secondary","data-test":"remove-task-button","data-test-tooltip-activator":"remove-task-button"},on:{"click":_vm.onRemoveSelected}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"left":"","medium":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('Delete Tasks'))+" ")],1)],1)]}}],null,false,3920699480)},[_c('span',{attrs:{"data-test-tooltip-content-for":"remove-task-button"}},[_vm._v(_vm._s(_vm.$t( _vm.canUpdateAssignment ? 'assignmentPage.tooltips.removeSelected' : 'assignmentPage.noEditPermission' )))])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"fab":"","large":"","elevation":"4","color":"secondary","disabled":_vm.missingFormData.length !== 0},on:{"click":_vm.saveAndPreview}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$i('assignment.previewAssignment')))])],1)]}}],null,false,3185720643)},[_c('span',[_vm._v(_vm._s(_vm.$t('assignmentPage.tooltips.previewAssignment')))])])],1)])],1),_c('v-row',{staticClass:"stemble-task-grid mt-3",attrs:{"no-gutters":""}},[_c('ag-grid-vue',{staticClass:"ag-theme-material",staticStyle:{"width":"100%","height":"350px"},attrs:{"id":"QuestionsAssignmentGrid","row-data-model":_vm.assignmentData.taskAssignments,"always-show-horizontal-scroll":"","row-selection":_vm.rowSelection,"grid-options":_vm.gridOptions,"column-defs":_vm.columnDefs,"default-col-def":_vm.defaultColDef,"animate-rows":true,"sorting-order":_vm.sortingOrder,"overlay-loading-template":_vm.overlayLoadingTemplate,"overlay-no-rows-template":_vm.overlayNoRowsTemplate,"components":_vm.components,"suppress-row-click-selection":true,"post-process-popup":_vm.postProcessPopup,"allow-context-menu-with-control-key":true,"icons":_vm.icons,"framework-components":_vm.frameworkComponents,"enter-navigates-vertically-after-edit":true,"row-drag-managed":_vm.canUpdateAssignment},on:{"data-model-changed":function($event){_vm.assignmentData.taskAssignments = $event;
          _vm.hasChangesInAssignmentData = true;},"row-drag-end":_vm.onRowDragEnd,"row-selected":function($event){return _vm.updateSelectedRows()},"grid-ready":_vm.onGridReady}})],1),_c('v-row',[_c('v-col',[(_vm.canUpdateAssignment)?_c('v-btn',{attrs:{"width":"90%","color":"secondary","rounded":"","disabled":_vm.missingFormData.length !== 0,"loading":_vm.isAssignmentSaving},on:{"click":function($event){return _vm.saveAssignment()}}},[_vm._v(" Save Assigned Tasks ")]):_vm._e()],1)],1)],1):_vm._e(),_c('try-it-dialog',{attrs:{"task":_vm.previewTask},on:{"close":function($event){_vm.previewTask = null}}}),_c('s-confirmation-dialog',{attrs:{"title":_vm.$t('assignmentPage.loseAccessDialog.title').toString(),"content":_vm.$t('assignmentPage.loseAccessDialog.content').toString(),"confirm-button-options":{
      text: _vm.$t('assignmentPage.saveAssignment').toString(),
      color: 'danger',
    },"confirm-action":function () { return _vm.saveAssignmentHandler([true, _vm.shouldRedirectToAssignmentSettings]); }},model:{value:(_vm.loseAccessDialogOpened),callback:function ($$v) {_vm.loseAccessDialogOpened=$$v},expression:"loseAccessDialogOpened"}}),_c('s-confirmation-dialog',{attrs:{"title":"Redirecting","content":"You have unsaved changes. Would you like to save them before editing the assignment settings?","confirm-button-options":{
      text: _vm.$t('assignmentPage.saveAssignment').toString(),
      color: 'primary',
    },"confirm-action":function () { return _vm.saveAssignmentHandler([false, true]); },"cancel-action":function () { return (_vm.shouldRedirectToAssignmentSettings = false); }},model:{value:(_vm.redirectingToInertiaDialog),callback:function ($$v) {_vm.redirectingToInertiaDialog=$$v},expression:"redirectingToInertiaDialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }