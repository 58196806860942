import {computed, Ref} from '@vue/composition-api';
import TaskTopic from '@/tasks/models/TaskTopic';
import {inject, makeGlobalSingleton} from '@/container';
import {DependencyType} from '@/container/types/DependencyType';
import {IOntologyTree} from '@/topic-ontology/types/IOntologyTree';
import {TopicOntologyTreeService} from '@/topic-ontology/services/TopicOntologyTreeService';

export const UseTopicOntologyTree = makeGlobalSingleton(() =>
  useTopicOntologyTree(inject(TopicOntologyTreeService))
);

export const useTopicOntologyTree = (
  treeService: DependencyType<typeof TopicOntologyTreeService>
) => {
  const ontologyTree: Ref<IOntologyTree> = computed(() => {
    const topics = TaskTopic.query().get();
    return treeService.buildTopicTree(topics);
  });

  return {
    ontologyTree,
  };
};
